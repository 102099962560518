.card {
  border: none;
  margin-bottom: 24px;
}

.accordion > .card > .card-header {
  margin-bottom: 0px;
}

.card .card-widgets {
  float: right;
  height: 16px;
}

.custom-accordion .card-header {
  background-color: white;
}

.card-title,
.card-header {
  margin-top: 0;
}

.card-linear-gradient {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.75),
    rgb(192, 217, 255)
  );
}

.card-auth-landing {
  background: rgba(76, 81, 94, 0.3);
  backdrop-filter: blur(10px);
  max-width: 600px;
}

.card-grey {
  background: rgba(76, 81, 94, 0.3) !important;
  backdrop-filter: blur(10px);
}

.card-console {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #eef2f7;
  -webkit-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.3);
  width: 100%;
  color: #202227;
}

.card-console-no-hover {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #eef2f7;
  -webkit-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.3);
  width: 100%;
}

.card-border-default {
  border: 1px solid rgb(232, 236, 253);
  -webkit-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.3);
}

.card-console:hover {
  background-color: #fff;
  background-clip: border-box;
  border: 0.5px solid #3392e8 !important;
  -webkit-box-shadow: 0 1px 10px 0 #3392e8 !important;
  -moz-box-shadow: 0 1px 10px 0 #3392e8 !important;
  box-shadow: 0 1px 10px 0 #3392e8 !important;
}

.border-card {
  border: 1px solid rgb(196, 207, 214);
}
