.table {
  width: 100%;
  margin-bottom: 1.5rem;
  color: #202227;
}

.table th,
.table td {
  padding: 0.95rem;
  vertical-align: top;
  border-top: none;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: none;
}

.table tbody + tbody {
  border-top: 2px solid #d8d8d8;
}

.table > :not(:first-child) {
  border-top: none;
}

.table > :not(caption) > * > * {
  border-color: rgba(216, 216, 216, var(--tw-border-opacity));
}

.table-sm th,
.table-sm td {
  padding: 0.5rem;
}

.table-bordered {
  border: 1px solid #d8d8d8;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #d8d8d8;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f1f3fa;
}

.table-hover tbody tr:hover {
  color: #202227;
  background-color: #f1f3fa;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #d8dafc;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #b6bbfa;
}

.table-hover .table-primary:hover {
  background-color: #c0c4fa;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #c0c4fa;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #baf2e2;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #80e6c9;
}

.table-hover .table-success:hover {
  background-color: #a5eed9;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #a5eed9;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c8e9f2;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #98d5e7;
}

.table-hover .table-info:hover {
  background-color: #b3e1ed;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #b3e1ed;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffecb8;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdc7a;
}

.table-hover .table-warning:hover {
  background-color: #ffe59f;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe59f;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fed1da;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #fcaabb;
}

.table-hover .table-danger:hover {
  background-color: #fdb8c6;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #fdb8c6;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fafbfd;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f6f8fb;
}

.table-hover .table-light:hover {
  background-color: #e8ecf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #e8ecf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c5c8cb;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #94999f;
}

.table-hover .table-dark:hover {
  background-color: #b8bbbf;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b8bbbf;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #98a6ad;
  background-color: #313a46;
  border-color: #414d5d;
}

.table .thead-light th {
  color: #202227;
  background-color: #f1f3fa;
  border-color: #d8d8d8;
}

.table-dark {
  color: #98a6ad;
  background-color: #313a46;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #414d5d;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (min-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1366.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.button-white-table {
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  font-size: 12px;
  color: #3392e8;
  border: 1px solid #3392e8 !important;
  background: #ffffff;
  border-radius: 2px;
}

.button-white-table:hover {
  outline: 2px solid #95beff;
  outline-offset: -2px;
}

.button-mizar-table {
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  font-size: 12px;
  color: #ffffff;
  background: #3392e8;
  border: 1px solid #3392e8;
  border-radius: 2px;
}

.button-mizar-table:hover {
  background: #286bd2;
  outline: 2px solid #95beff;
  outline-offset: -2px;
}

.button-mizar-table.disabled,
.button-mizar-table:disabled {
  color: #313a46;
  background-color: #d8d8d8;
  border-color: #d8d8d8;
}

.button-white-table.disabled,
.button-white-table:disabled {
  color: #313a46;
  background-color: #d8d8d8;
  border-color: #d8d8d8 !important;
}
