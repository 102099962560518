//
// accordions.scss
//

.accordion {
  .accordion-collapse {
    visibility: visible;
  }
}

.custom-accordion {
  .card {
    box-shadow: none;
  }

  .card-header {
    background-color: $gray-100;
  }

  .card-body {
    border: 1px solid $gray-100;
  }

  .accordion-arrow {
    font-size: 1.2rem;
    position: absolute;
    right: 0;
  }
  a {
    &.collapsed {
      i.accordion-arrow {
        &:before {
          content: '\F142';
        }
      }
    }
  }
}

.custom-accordion-title {
  color: $custom-accordion-title-color;
  position: relative;

  &:hover {
    color: lighten($custom-accordion-title-color, 7.5%);
  }
}

.accordion {
  > .card {
    > .card-header {
      border-radius: 0;
      margin-bottom: -1px;
    }
  }
}

.accordion-button {
  text-align: left;
  background-color: white;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}

.accordion-button:not(.collapsed) {
  color: #3392e8;
  background-color: white;
  box-shadow: none;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #c8cbcf;
  outline: 0;
  box-shadow: none;
}
