body[theme='dark'].dex #root {
  background: #121212;
  color: #f5f5f5;
}

body[theme='dark'] {
  #root {
    background: #202227;
    color: #ffffff;
  }

  .card {
    background: #313a46;
    color: #fdfdfd;
    border: 1px solid #646464;
  }

  .card-header {
    background: #313a46;
    color: #fdfdfd;
  }

  .border {
    border: 1px solid #646464;
  }

  .border-new {
    border: none;
  }

  .card-footer {
    background: #313a46;
    color: #fdfdfd;
  }

  .card-linear-gradient {
    background: linear-gradient(to bottom, #3392e8, #202227);
  }

  .card-console-no-hover {
    background: #313a46;
    color: #fdfdfd;
    border: 1px solid #646464;
  }

  .card-border {
    background: #313a46;
    color: #fdfdfd;
    border: 1px solid #646464;
  }

  .card-console {
    background: #313a46;
    color: #fdfdfd;
    border: 1px solid #646464;
  }

  .navbar-custom {
    background: #313a46;
    color: #fdfdfd;
    border-bottom: 1px solid #646464;
  }

  .nav-user {
    background: #313a46;
    border: 1px solid #646464;
  }

  .section-navbar {
    background: #313a46;
    border: none;
  }

  .nav-tabs > li > a,
  .nav-pills > li > a {
    color: #fdfdfd;
  }

  .nav-tabs.nav-bordered {
    border-bottom: 2px solid rgba(206, 212, 218, 0.2);
  }

  .table {
    color: #fdfdfd;
    border-color: #646464;
  }

  .table-dark-mode {
    color: #202227 !important;
    border-color: #646464;
  }

  .table th,
  .table td {
    border-top: none;
    border-color: #646464;
  }

  .form-control-sm {
    color: #fdfdfd;
    background-color: #404954;
    background-clip: padding-box;
    border: 1px solid #4a525d;
  }

  .form-control {
    color: #fdfdfd;
    background-color: #404954;
    background-clip: padding-box;
    border: 1px solid #4a525d;
  }

  .form-bg-tag {
    color: #fdfdfd;
    background-color: #404954;
    background-clip: padding-box;
    border: 1px solid #4a525d;
  }

  .form-bg-tag-important {
    color: #fdfdfd;
    background-color: #404954;
    background-clip: padding-box;
    border: 1px solid #4a525d;
  }

  .css-1s2u09g-control {
    color: #fdfdfd;
    background-color: #404954;
    border: 1px solid #4a525d;
  }

  .css-qc6sy-singleValue {
    color: #fdfdfd;
  }

  .css-1s2u09g-control {
    color: #fdfdfd;
    background-color: #404954;
    border: 1px solid #4a525d;
  }

  .css-26l3qy-menu {
    color: #fdfdfd;
    background-color: #404954;
    border: 1px solid #4a525d;
  }

  .css-1pahdxg-control {
    color: #fdfdfd;
    background-color: #404954;
  }

  a .grey {
    color: #fdfdfd;
  }

  a .grey:hover {
    color: #3392e8;
  }

  .side-nav {
    .side-nav-link {
      color: $menu-item-light;

      &:hover,
      &:focus,
      &:active {
        color: #3392e8 !important;
      }
    }

    .menuitem-active {
      > a {
        color: #3392e8 !important;
      }
    }
  }

  a .grey-dark {
    color: #202227 !important;
  }

  .alert-light {
    color: #fdfdfd;
    background-color: #5e636e;
    border: none;
  }

  .css-1rhbuit-multiValue {
    background-color: #5e636e !important;
    border: none;
  }

  .css-1n7v3ny-option {
    color: #404954;
  }

  .css-12jo7m5 {
    color: #fdfdfd;
  }

  .modal-content {
    background-color: #404954;
    border: 1px solid #4a525d;
    color: #fdfdfd;
  }

  .dropdown-custom {
    color: #fdfdfd;
    background-color: #404954;
  }

  .dropdown-menu {
    color: #fdfdfd;
    background-color: #404954;
  }

  .dropdown-item {
    color: #fdfdfd;
  }

  .grey-dropdown {
    color: #fdfdfd;
  }

  .apexcharts-tooltip.apexcharts-theme-light {
    background: #454a56;
    color: white;
    border-color: #878a93;
  }

  .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    background: #454a56;
    border-color: #878a93;
  }

  .form-control:disabled {
    background-color: #eef2f7;
    opacity: 1;
    color: #fdfdfd;
  }

  .text-dark {
    color: #202227;
  }

  .text-white-dark-mode {
    color: white !important;
  }

  .text-standard-color {
    color: #fdfdfd !important;
  }

  .bg-mizar-dark-mode {
    background: #3392e8 !important;
  }

  .progress {
    background-color: #fdfdfd;
  }

  .btn-close {
    background: transparent
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e3eaef'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
      center/1em auto no-repeat;
  }

  .button-menu-mobile {
    color: #eef2f7;
  }

  .custom-accordion .card-header {
    background-color: #404954;
    border: 1px solid #4a525d;
  }

  .custom-accordion-title {
    color: #fdfdfd;
  }

  .accordion-button {
    background-color: #404954;
    color: #fdfdfd;
  }

  .accordion-button:not(.collapsed) {
    color: #3392e8;
    background-color: #404954;
  }

  .accordion-button:focus {
    z-index: 3;
    border: 1px solid #4a525d;
    outline: 0;
    box-shadow: none;
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: #6c757d;
  }

  .dropdown-item-landing:hover,
  .dropdown-item-landing:focus {
    background-color: white;
  }

  .content .content-overlay {
    background: rgba(0, 0, 0, 0.7);
  }

  a .grey-important {
    color: #fdfdfd !important;
    text-decoration: none;
    background-color: transparent;
  }
  a .grey-important:hover {
    color: #3392e8 !important;
    text-decoration: none;
  }
}
