body {
  &.loading {
    visibility: hidden;
  }
}

button,
a {
  outline: none !important;
}

// Forms

label {
  font-weight: $font-weight-semibold;
}

b,
strong {
  font-weight: $font-weight-bold;
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

button,
input[type='submit'],
input[type='reset'] {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  background: none;
  color: inherit;

  padding: 0;
  cursor: pointer;
  outline: inherit;
}

.captcha-protected-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #ffffff;
  background: #3392e8;
  border: none;
  position: relative;
  overflow: hidden;
  cursor: not-allowed;
  width: 100%;
  border-radius: 4px;
}

.captcha-protected-button button {
  position: relative;
  left: 0;
  transition: all 0.35s ease-Out;
  color: #ffffff;
}

.disabled-message {
  width: 100%;
  height: 100%;
  color: #313a46;
  background-color: #eef2f7;
  border-color: #eef2f7;
  left: -100%;
  position: absolute;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.35s ease-Out;
  bottom: 0;
}

.captcha-protected-button:hover .disabled-message {
  left: 0;
}

.captcha-protected-button:hover button {
  left: 100%;
}

.button-mizar {
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  font-size: 16px;
  color: #ffffff;
  background: #3392e8;
  border-radius: 4px;
  border: none;
}

.button-mizar:hover,
.button-mizar:focus {
  background: #286bd2;
  color: #ffffff;
  outline: 4px solid #95beff;
  outline-offset: -4px;
}

.button-mizar.disabled:not(.loading),
.button-mizar:disabled:not(.loading) {
  color: #313a46;
  background-color: #eef2f7;
  border-color: #eef2f7;
}

.button-white.disabled,
.button-white:disabled {
  color: #313a46;
  background-color: #eef2f7;
  border-color: #eef2f7;
}

.button-white {
  justify-content: center;
  align-items: center;
  padding: 16px 32px !important;
  font-size: 16px;
  border: 1px solid #3392e8 !important;
  color: #3392e8 !important;
  background: white;
  border-radius: 2px !important;
}

.button-white:hover {
  outline: 4px solid #95beff !important;
  outline-offset: -4px !important;
}

.button-white-small {
  justify-content: center;
  align-items: center;
  padding: 8px 32px;
  font-size: 16px;
  border: 1px solid #3392e8 !important;
  color: #3392e8 !important;
  background: white;
  border-radius: 2px !important;
}

.button-white-small:hover {
  outline: 4px solid #95beff !important;
  outline-offset: -4px !important;
}

.button-mizar-small {
  justify-content: center;
  align-items: center;
  padding: 8px 32px;
  font-size: 16px;
  color: #ffffff !important;
  background: #3392e8 !important;
  border-radius: 2px;
}

.button-mizar-small:hover {
  background: #286bd2;
  outline: 4px solid #95beff !important;
  outline-offset: -4px !important;
}
