.myHighlightClass {
  z-index: 99999999;
}

.introjs-tooltip {
  min-width: 350px;
  max-width: 500px;
  color: #202227 !important;
}

.introjs-tooltiptext {
  font-size: 14px;
}

.introjs-nextbutton:focus {
  justify-content: center;
  align-items: center;
  padding: 8px 32px;
  font-size: 16px;
  margin: 0px 7px;
  color: #ffffff !important;
  border: none;
  background: #3392e8;
  border-radius: 2px;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.introjs-nextbutton {
  justify-content: center;
  align-items: center;
  padding: 8px 32px;
  font-size: 16px;
  margin: 0px 7px;
  color: #ffffff !important;
  border: none;
  background: #3392e8;
  border-radius: 2px;
  text-shadow: none;
}

.introjs-prevbutton {
  justify-content: center;
  align-items: center;
  padding: 8px 32px;
  font-size: 16px;
  margin: 0px 7px;
  color: #3392e8 !important;
  border: 1px solid #3392e8;
  background: #ffffff !important;
  border-radius: 2px;
  text-shadow: none;
}

.introjs-prevbutton:focus {
  justify-content: center;
  align-items: center;
  padding: 8px 32px;
  font-size: 16px;
  margin: 0px 7px;
  color: #3392e8 !important;
  border: 1px solid #3392e8;
  background: #ffffff !important;
  border-radius: 2px;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.introjs-prevbutton:hover {
  outline: 4px solid #95beff !important;
  outline-offset: -4px !important;
}

.introjs-nextbutton:hover {
  background: #286bd2;
  color: #ffffff;
  outline: 4px solid #95beff;
  outline-offset: -4px;
}

.introjs-fixedTooltip {
  box-shadow: rgb(40 107 210 / 80%) 0px 0px 1px 2px,
    rgb(33 33 33 / 50%) 0px 0px 0px 5000px !important;
}

.introjs-bullets ul li a.active {
  width: 15px;
  background: #286bd2;
}
