.background-auth-landing {
  position: relative;
  background: #07080f;
}

.background-auth-landing:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 1920px;
  height: 1080px;
  opacity: 0.75;
  background-image: url('../../../img/landing_page_new/backgrounds/bg_auth.jpg');
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
