h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 400;
  line-height: 1.1;
}

h1,
.h1 {
  font-size: 2.25rem;
  font-weight: 600;
}

p,
.p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
}

h2,
.h2 {
  font-size: 1.875rem;
  font-weight: 600;
}

h3,
.h3 {
  font-size: 1.5rem;
  font-weight: 600;
}

h4,
.h4 {
  font-size: 1.125rem;
}

h5,
.h5 {
  font-size: 0.9375rem;
}

h6,
.h6 {
  font-size: 0.75rem;
}

.lead {
  font-size: 1.125rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #eef2f7;
}

small,
.small {
  font-size: 0.75rem;
  font-weight: 400;
}

.font-10 {
  font-size: 10px !important;
}

.font-11 {
  font-size: 11px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-26 {
  font-size: 26px !important;
}

.font-30 {
  font-size: 30px !important;
}

.font-35 {
  font-size: 35px !important;
}

.font-40 {
  font-size: 40px !important;
}

.font-50 {
  font-size: 50px !important;
}

.font-100 {
  font-size: 100px !important;
}

@media (max-width: 576px) {
  .font-sm-10 {
    font-size: 10px !important;
  }

  .font-sm-11 {
    font-size: 11px !important;
  }

  .font-sm-12 {
    font-size: 12px !important;
  }

  .font-sm-13 {
    font-size: 13px !important;
  }

  .font-sm-14 {
    font-size: 14px !important;
  }

  .font-sm-15 {
    font-size: 15px !important;
  }

  .font-sm-16 {
    font-size: 16px !important;
  }

  .font-sm-18 {
    font-size: 18px !important;
  }

  .font-sm-20 {
    font-size: 20px !important;
  }

  .font-sm-22 {
    font-size: 22px !important;
  }

  .font-sm-24 {
    font-size: 24px !important;
  }

  .font-sm-30 {
    font-size: 30px !important;
  }

  .font-sm-35 {
    font-size: 35px !important;
  }
}

@media (min-width: 992px) {
  .font-lg-10 {
    font-size: 10px !important;
  }

  .font-lg-11 {
    font-size: 11px !important;
  }

  .font-lg-12 {
    font-size: 12px !important;
  }

  .font-lg-13 {
    font-size: 13px !important;
  }

  .font-lg-14 {
    font-size: 14px !important;
  }

  .font-lg-15 {
    font-size: 15px !important;
  }

  .font-lg-16 {
    font-size: 16px !important;
  }

  .font-lg-18 {
    font-size: 18px !important;
  }

  .font-lg-20 {
    font-size: 20px !important;
  }

  .font-lg-22 {
    font-size: 22px !important;
  }

  .font-lg-24 {
    font-size: 24px !important;
  }

  .font-lg-26 {
    font-size: 26px !important;
  }

  .font-lg-30 {
    font-size: 30px !important;
  }

  .font-lg-35 {
    font-size: 35px !important;
  }
  .font-lg-40 {
    font-size: 35px !important;
  }

  .font-lg-48 {
    font-size: 48px !important;
  }
  .font-lg-60 {
    font-size: 60px !important;
  }
}

@media (max-width: 768px) {
  .font-md-10 {
    font-size: 10px !important;
  }

  .font-md-11 {
    font-size: 11px !important;
  }

  .font-md-12 {
    font-size: 12px !important;
  }

  .font-md-13 {
    font-size: 13px !important;
  }

  .font-md-14 {
    font-size: 14px !important;
  }

  .font-md-15 {
    font-size: 15px !important;
  }

  .font-md-16 {
    font-size: 16px !important;
  }

  .font-md-18 {
    font-size: 18px !important;
  }

  .font-md-20 {
    font-size: 20px !important;
  }

  .font-md-22 {
    font-size: 22px !important;
  }

  .font-md-24 {
    font-size: 24px !important;
  }

  .font-md-30 {
    font-size: 30px !important;
  }

  .font-md-35 {
    font-size: 35px !important;
  }
}

.text-mizar {
  color: #3392e8 !important;
}

.text-backer {
  color: #cacaca;
}

a {
  color: #3392e8;
  text-decoration: none;
  background-color: transparent;
}

a .grey {
  color: #202227;
  text-decoration: none;
  background-color: transparent;
}

a .grey-important {
  color: #202227 !important;
  text-decoration: none;
  background-color: transparent;
}
a .grey-important:hover {
  color: #3392e8 !important;
  text-decoration: none;
}
a .grey:hover {
  color: #3392e8;
  text-decoration: none;
}

.grey:hover {
  color: #3392e8;
  text-decoration: none;
}

a .grey-light {
  color: #98a6ad;
  text-decoration: none;
  background-color: transparent;
}

a .grey-light:hover {
  color: #59636b;
  text-decoration: none;
}

a .white {
  color: white;
  text-decoration: none;
  background-color: transparent;
}

a .white:hover {
  color: #3392e8;
  text-decoration: none;
}

a.no-style {
  color: #202227 !important;
  text-decoration: none !important;
  background-color: transparent;
}

a .grey:hover {
  color: #3392e8;
  text-decoration: none;
}

a .no-style:hover {
  color: #202227 !important;
  text-decoration: none !important;
}

a:hover {
  // blue-600
  color: #2e85d3;
  text-decoration: none;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

.text-white {
  color: white !important;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 2px;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    -webkit-transition: none;
    transition: none;
  }
}

a.badge:hover,
a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #727cf5;
}

a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #4250f2;
}

a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(114, 124, 245, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(114, 124, 245, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #202227;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #0acf97;
}

a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #089e74;
}

a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.5);
}

.badge-info {
  color: #fff;
  background-color: rgba(62, 179, 216, 1);
}

a.badge-info:hover,
a.badge-info:focus {
  color: #fff;
  background-color: #2991ae;
}

a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(57, 175, 209, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(57, 175, 209, 0.5);
}

.badge-warning {
  color: #313a46;
  background-color: #ffbc00;
}

a.badge-warning:hover,
a.badge-warning:focus {
  color: #313a46;
  background-color: #cc9600;
}

a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 188, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 188, 0, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #fa5c7c;
}

a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #f82b54;
}

a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 92, 124, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(250, 92, 124, 0.5);
}

.badge-light {
  color: #313a46;
  background-color: #eef2f7;
}

a.badge-light:hover,
a.badge-light:focus {
  color: #313a46;
  background-color: #cbd7e7;
}

a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(238, 242, 247, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(238, 242, 247, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #313a46;
}

a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #1c2128;
}

a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(49, 58, 70, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(49, 58, 70, 0.5);
}

.text-success {
  color: #0acf97 !important;
}

.text-warning {
  color: #ffbc00 !important;
}

.text-info {
  color: rgba(62, 179, 216, 1) !important;
}

.text-danger {
  color: #fa5c7c !important;
}

.text-standard-color {
  color: #202227 !important;
}
.text-underline {
  border-bottom: 1.5px solid #3392e8;
  padding-bottom: 7px;
}
