.dropdown-menu-animated {
  &.dropdown-menu-end[style] {
    left: auto !important;
    right: 0 !important;
  }
}

// Dropdown Animated (Custom)
.dropdown-menu-animated {
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  position: absolute;
  margin: 0;
  z-index: 1000;

  &.show {
    top: 100% !important;
  }

  i {
    display: inline-block;
  }

  &.dropdown-menu[data-popper-placement^='right'],
  &.dropdown-menu[data-popper-placement^='top'],
  &.dropdown-menu[data-popper-placement^='left'] {
    top: auto !important;
    animation: none !important;
  }
}

@keyframes DropDownSlide {
  100% {
    transform: translateY(0);
  }
  0% {
    transform: translateY(10px);
  }
}

// Dropdown Large (Custom)
@media (min-width: 600px) {
  .dropdown-lg {
    width: $dropdown-lg-width;
  }
}

// Dropdown with Icons
.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  display: block;
  border: 1px solid transparent;
  color: $dropdown-link-color;

  img {
    height: 24px;
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    outline: 2px solid #95beff;
    outline-offset: -2px;
  }
}

// Dropdown arrow hide
.arrow-none {
  &:after {
    display: none;
  }
}

.dropdown-custom {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.45rem 0.9rem;
  font-size: 12px;
  font-weight: 350;
  line-height: 1.5;
  color: #202227;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: none;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;

  &:after {
    display: none;
  }
}

.dropdown-custom:hover {
  outline: 2px solid #95beff;
  background: transparent;
  outline-offset: -2px;
  color: #202227;
}

.dropdown-custom:active {
  outline: 2px solid #95beff;
  background: transparent;
  outline-offset: -2px;
  color: #202227;
}

.dropdown-custom:focus {
  outline: 2px solid #95beff;
  background: transparent;
  outline-offset: -2px;
  color: #202227;
}

.show > .dropdown-custom.dropdown-toggle {
  outline: 2px solid #95beff;
  background: transparent;
  outline-offset: -2px;
  color: #202227;
}

.show > .dropdown-custom.dropdown-toggle:focus {
  box-shadow: none;
}

.css-1rhbuit-multiValue {
  border: 1px solid rgb(232, 236, 253);
  background-color: white !important;
}

.css-1n7v3ny-option {
  text-align: left;
}

.css-319lph-ValueContainer {
  text-align: left;
}

.css-9gakcf-option {
  text-align: left;
}

.css-yt9ioa-option {
  text-align: left;
}

.css-yt9ioa-option {
  text-align: left;
}

.removecaret .dropdown-toggle::after {
  display: none !important;
}

.css-qc6sy-singleValue {
  text-align: left;
}

.grey-dropdown {
  color: #6c757d;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #2c343f;
}

.dropdown-item-active {
  border-left: solid 3px #3392e8;
}
.dropdown-no-hover {
  background: none !important;
}

.dropdown-no-toggle::after {
  content: none !important;
}

/* Dropdown Button */
.dropdown-button-top-navbar {
  background-color: transparent;
  color: white;
  font-size: 18px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown-top-navbar {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content-top-navbar {
  display: none;
  position: absolute;
  background: linear-gradient(180deg, #0c070f 0%, #4c515e 215.37%);
  color: white;
  left: 50%;
  right: auto;
  width: 700px;
  text-align: center;
  transform: translate(-50%, 0);
  padding: 32px 48px 32px 48px;
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content-top-navbar a {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Show the dropdown menu on hover */
.dropdown-top-navbar:hover .dropdown-content-top-navbar {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown-top-navbar:hover .dropdown-button-top-navbar {
  color: #3392e8;
}
