@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --header-h: 64px;
  --white-100: theme(colors.white.100);
  --white-800: theme(colors.white.800);
  --white-700: theme(colors.white.700);
  --blue-500: theme(colors.mizar-blue.500);
  --blue-500-rgb: 51, 146, 232;
  --black-600: theme(colors.black.600);
  --black-700: theme(colors.black.700);
  --black-800: theme(colors.black.800);
  --black-900: theme(colors.black.900);
  --white: theme(colors.white.DEFAULT);
  --green-success: #88d693;
  --green-success-rgb: 136, 214, 147;
  --red-warning: #f04866;
  --red-warning-rgb: 240, 72, 102;
  scroll-padding-top: var(--header-h);
}

#root {
  background: theme(colors.white.100);
}

@layer utilities {
  .clip-mizar-hexagon {
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  }
  .hexagon-border-black-50 {
    background: linear-gradient(
          to bottom left,
          transparent calc(50% - 1px),
          theme(colors.black.50) calc(50% - 1px)
        )
        0 100%/50% 25% no-repeat,
      linear-gradient(
          to bottom right,
          transparent calc(50% - 1px),
          theme(colors.black.50) calc(50% - 1px)
        )
        100% 100%/50% 25% no-repeat,
      linear-gradient(
          to top right,
          transparent calc(50% - 1px),
          theme(colors.black.50) calc(50% - 1px)
        )
        100% 0/50% 25% no-repeat,
      linear-gradient(
          to top left,
          transparent calc(50% - 1px),
          theme(colors.black.50) calc(50% - 1px)
        )
        0 0/50% 25% no-repeat,
      linear-gradient(theme(colors.black.50), theme(colors.black.50)) 0 0/1px
        100% no-repeat,
      linear-gradient(theme(colors.black.50), theme(colors.black.50)) 100% 0/1px
        100% no-repeat,
      transparent;
  }
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.custom-html p {
  margin-bottom: 0;
}

.custom-html p:empty::before {
  content: '';
  display: inline-block;
}

.custom-html[class*='line-clamp'] p {
  display: inline;
}

.custom-html[class*='line-clamp'] p::after {
  content: ' \A\A';
  white-space: pre;
}

button {
  border: none;
}

input:disabled,
textarea:disabled,
input:disabled::placeholder,
textarea:disabled::placeholder {
  -webkit-text-fill-color: currentcolor; /* 1. sets text fill to current `color` for safari */
  opacity: 1; /* 2. correct opacity on iOS */
}

.magic-button {
  --border-bottom-width: 1px;
  --border-color: rgba(103, 145, 249, 1);
  --border-left-width: 1px;
  --border-right-width: 1px;
  --border-style: solid;
  --border-top-width: 1px;
  background: linear-gradient(
    90deg,
    rgb(51, 146, 232) 0%,
    rgb(46, 133, 211) 78.9465%
  );
  height: 100%;
  opacity: 1;
  box-shadow: rgb(51, 146, 232) 0px 6px 34px -6px,
    rgb(168, 209, 255) 0px 5px 10px -8px inset,
    rgb(168, 209, 255) 0px -5px 10px -8px inset,
    rgb(168, 209, 255) -5px 0px 10px -8px inset,
    rgb(168, 209, 255) 5px 0px 10px -8px inset;
}

.magic-button:hover {
  --border-bottom-width: 1px;
  --border-color: rgb(46, 133, 211);
  --border-left-width: 1px;
  --border-right-width: 1px;
  --border-style: solid;
  --border-top-width: 1px;
  background: linear-gradient(
    90deg,
    rgb(51, 146, 232) 0%,
    rgb(118, 182, 240) 78.9465%
  );
  height: 100%;
  opacity: 1;
  box-shadow: rgb(51, 146, 232) 0px 6px 34px -3px,
    rgb(168, 209, 255) 0px 5px 20px -7px inset,
    rgb(168, 209, 255) 0px -5px 20px -7px inset,
    rgb(168, 209, 255) -5px 0px 20px -7px inset,
    rgb(168, 209, 255) 5px 0px 20px -7px inset;
}

.magic:hover {
  --border-bottom-width: 1px;
  --border-color: rgb(46, 133, 211);
  --border-left-width: 1px;
  --border-right-width: 1px;
  --border-style: solid;
  --border-top-width: 1px;
  height: 100%;
  opacity: 1;
  box-shadow: rgb(51, 146, 232) 0px 6px 34px -3px,
    rgb(168, 209, 255) 0px 5px 4px -4px inset,
    rgb(168, 209, 255) 0px -5px 4px -4px inset,
    rgb(168, 209, 255) -5px 0px 4px -4px inset,
    rgb(168, 209, 255) 5px 0px 4px -4px inset;
}

.react-datepicker__header {
  background-color: #363a43 !important;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: white !important;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: white !important;
}

.react-datepicker {
  background-color: #454a56 !important;
  border: #454a56 !important;
  margin-left: 64px;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #202227 #2a2d34;
}
.dex-chart-wrapper {
  width: 100%;
  height: 100%;
  transition: height 0.3s ease-in-out;
}
